import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c841a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popover-warning-list" }
const _hoisted_2 = { for: "new-password" }
const _hoisted_3 = { for: "verification-password" }
const _hoisted_4 = { class: "w-100 d-flex flex-row justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_popover = _resolveComponent("b-popover")!
  const _component_b_button_group = _resolveComponent("b-button-group")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_site_spinner = _resolveComponent("site-spinner")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "user-management",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "body-class": "modal-body-no-spacing",
    "ok-only": "",
    "ok-title": _ctx.$t('Close'),
    "ok-variant": "secondary",
    scrollable: "",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.$t('User Management')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, { class: "justify-content-center align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { class: "my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, {
                    label: _ctx.$t('Search'),
                    "label-cols-sm": "2",
                    "label-size": "sm",
                    "label-for": "filterInput",
                    class: "mb-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, { size: "sm" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            modelValue: _ctx.filter,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                            type: "search",
                            id: "filterInput"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                disabled: !_ctx.filter,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = ''))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Clear")), 1)
                                ], undefined, true),
                                _: 1
                              }, 8, ["disabled"])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }, 8, ["label"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }),
      (_ctx.getUsers.length > 0 && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_b_table, {
            key: 0,
            class: "mb-0",
            striped: "",
            "sticky-header": "",
            responsive: "sm",
            modelValue: _ctx.currentItems,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentItems) = $event)),
            items: _ctx.getUsers,
            fields: _ctx.fields,
            filter: _ctx.filter
          }, {
            "cell(messages)": _withCtx((row) => [
              _createElementVNode("div", null, [
                (row.item.messages && row.item.messages.length > 0)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      class: "question-link text-warning fa-lg",
                      icon: "exclamation-triangle",
                      id: 'user-warning-' + row.index
                    }, null, 8, ["id"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_b_popover, {
                  ref: "popover",
                  target: 'user-warning-' + row.index,
                  triggers: "hover focus",
                  variant: "warning",
                  placement: "left"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("ul", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.messages, (message, index) => {
                        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(message), 1))
                      }), 128))
                    ])
                  ], undefined, true),
                  _: 2
                }, 1032, ["target"])
              ])
            ]),
            "cell(actions)": _withCtx((row) => [
              _createVNode(_component_b_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    variant: "primary",
                    size: "sm",
                    disabled: row.detailsShowing,
                    onClick: ($event: any) => (_ctx.toggleDetails(row.item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Edit")), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["disabled", "onClick"]),
                  _createVNode(_component_b_button, {
                    variant: "danger",
                    size: "sm",
                    onClick: ($event: any) => (_ctx.onDelete(row.item.username, row.item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Delete")), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["onClick"])
                ], undefined, true),
                _: 2
              }, 1024)
            ]),
            "cell(lastLogin)": _withCtx((row) => [
              _createTextVNode(_toDisplayString(row.item.lastLogin || _ctx.dateTime), 1)
            ]),
            "row-details": _withCtx((row) => [
              _createVNode(_component_b_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, { sm: "3" }, {
                            default: _withCtx(() => [
                              _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("New password")), 1)
                            ], undefined, true),
                            _: 1
                          }),
                          _createVNode(_component_b_col, { sm: "9" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input_group, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_form_input, {
                                    id: "new-password",
                                    type: _ctx.showPassword ? 'text' : 'password',
                                    modelValue: _ctx.password,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event))
                                  }, null, 8, ["type", "modelValue"]),
                                  _createVNode(_component_b_input_group_append, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_button, {
                                        class: "password-display-btn",
                                        tabindex: "-1",
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                                      }, {
                                        default: _withCtx(() => [
                                          (_ctx.showPassword)
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 0,
                                                icon: ['far', 'eye-slash']
                                              }))
                                            : _createCommentVNode("", true),
                                          (!_ctx.showPassword)
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 1,
                                                icon: ['far', 'eye']
                                              }))
                                            : _createCommentVNode("", true)
                                        ], undefined, true),
                                        _: 1
                                      })
                                    ], undefined, true),
                                    _: 1
                                  })
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      }),
                      _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, { sm: "3" }, {
                            default: _withCtx(() => [
                              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("Password verification")), 1)
                            ], undefined, true),
                            _: 1
                          }),
                          _createVNode(_component_b_col, { sm: "9" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input_group, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_form_input, {
                                    id: "verification-password",
                                    type: _ctx.showVerification ? 'text' : 'password',
                                    modelValue: _ctx.verificationPassword,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.verificationPassword) = $event))
                                  }, null, 8, ["type", "modelValue"]),
                                  _createVNode(_component_b_input_group_append, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_button, {
                                        class: "password-display-btn",
                                        tabindex: "-1",
                                        onClick: ($event: any) => (
                          _ctx.showVerification =
                            !_ctx.showVerification && row.item._showDetails
                        )
                                      }, {
                                        default: _withCtx(() => [
                                          (_ctx.showVerification)
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 0,
                                                icon: ['far', 'eye-slash']
                                              }))
                                            : _createCommentVNode("", true),
                                          (!_ctx.showVerification)
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 1,
                                                icon: ['far', 'eye']
                                              }))
                                            : _createCommentVNode("", true)
                                        ], undefined, true),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ], undefined, true),
                                    _: 2
                                  }, 1024)
                                ], undefined, true),
                                _: 2
                              }, 1024)
                            ], undefined, true),
                            _: 2
                          }, 1024)
                        ], undefined, true),
                        _: 2
                      }, 1024),
                      _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, { "offset-md": "3" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_checkbox, {
                                id: "is-admin",
                                name: "is-admin",
                                modelValue: row.item.isAdmin,
                                "onUpdate:modelValue": ($event: any) => ((row.item.isAdmin) = $event)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Is admin")), 1)
                                ], undefined, true),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue"])
                            ], undefined, true),
                            _: 2
                          }, 1024)
                        ], undefined, true),
                        _: 2
                      }, 1024),
                      _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, { "offset-md": "3" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                type: "submit",
                                size: "sm",
                                variant: "primary",
                                onClick: _withModifiers(($event: any) => (
                      _ctx.updatePassword(
                        row.item.id,
                        _ctx.password,
                        _ctx.verificationPassword,
                        row.item.isAdmin,
                      )
                    ), ["prevent"])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Save")), 1)
                                ], undefined, true),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_b_button, {
                                variant: "secondary",
                                size: "sm",
                                onClick: row.toggleDetails
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Close")), 1)
                                ], undefined, true),
                                _: 2
                              }, 1032, ["onClick"])
                            ], undefined, true),
                            _: 2
                          }, 1024)
                        ], undefined, true),
                        _: 2
                      }, 1024)
                    ], undefined, true),
                    _: 2
                  }, 1024)
                ], undefined, true),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["modelValue", "items", "fields", "filter"]))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_site_spinner, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    "modal-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$root.$emit('bv::show::modal', 'user-create')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Add user")), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_button, {
          variant: "secondary",
          class: "float-right",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.show = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Close")), 1)
          ], undefined, true),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["ok-title", "modelValue", "title"]))
}