<template>
  <b-modal
    id="user-management"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    body-class="modal-body-no-spacing"
    ok-only
    :ok-title="$t('Close')"
    ok-variant="secondary"
    scrollable
    v-model="show"
    :title="$t('User Management')"
  >
    <template v-slot:default>
      <b-card-body>
        <b-row class="justify-content-center align-items-center">
          <b-col class="my-1">
            <b-form-group
              :label="$t('Search')"
              label-cols-sm="2"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">{{
                    $t("Clear")
                  }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-table
        class="mb-0"
        striped
        sticky-header
        responsive="sm"
        v-if="getUsers.length > 0 && !isLoading"
        v-model="currentItems"
        :items="getUsers"
        :fields="fields"
        :filter="filter"
      >
        <template v-slot:cell(messages)="row">
          <div>
            <font-awesome-icon
              class="question-link text-warning fa-lg"
              icon="exclamation-triangle"
              v-if="row.item.messages && row.item.messages.length > 0"
              :id="'user-warning-' + row.index"
            />
            <b-popover
              ref="popover"
              :target="'user-warning-' + row.index"
              triggers="hover focus"
              variant="warning"
              placement="left"
            >
              <ul class="popover-warning-list">
                <li v-for="(message, index) in row.item.messages" :key="index">
                  {{ message }}
                </li>
              </ul>
            </b-popover>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <b-button
              variant="primary"
              size="sm"
              :disabled="row.detailsShowing"
              @click="toggleDetails(row.item)"
              >{{ $t("Edit") }}</b-button
            >
            <b-button
              variant="danger"
              size="sm"
              @click="onDelete(row.item.username, row.item.id)"
              >{{ $t("Delete") }}</b-button
            >
          </b-button-group>
        </template>
        <!-- last login row -->
        <template v-slot:cell(lastLogin)="row">
          {{ row.item.lastLogin || dateTime }}
        </template>
        <template v-slot:row-details="row">
          <b-card>
            <b-form>
              <b-row class="align-items-center my-1">
                <b-col sm="3">
                  <label for="new-password">{{ $t("New password") }}</label>
                </b-col>
                <b-col sm="9">
                  <b-input-group>
                    <b-form-input
                      id="new-password"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        class="password-display-btn"
                        tabindex="-1"
                        @click="showPassword = !showPassword"
                      >
                        <font-awesome-icon
                          :icon="['far', 'eye-slash']"
                          v-if="showPassword"
                        />
                        <font-awesome-icon
                          :icon="['far', 'eye']"
                          v-if="!showPassword"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="align-items-center my-1">
                <b-col sm="3">
                  <label for="verification-password">{{
                    $t("Password verification")
                  }}</label>
                </b-col>
                <b-col sm="9">
                  <b-input-group>
                    <b-form-input
                      id="verification-password"
                      :type="showVerification ? 'text' : 'password'"
                      v-model="verificationPassword"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        class="password-display-btn"
                        tabindex="-1"
                        @click="
                          showVerification =
                            !showVerification && row.item._showDetails
                        "
                      >
                        <font-awesome-icon
                          :icon="['far', 'eye-slash']"
                          v-if="showVerification"
                        />
                        <font-awesome-icon
                          :icon="['far', 'eye']"
                          v-if="!showVerification"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="align-items-center my-1">
                <b-col offset-md="3">
                  <b-form-checkbox
                    id="is-admin"
                    name="is-admin"
                    v-model="row.item.isAdmin"
                    >{{ $t("Is admin") }}</b-form-checkbox
                  >
                </b-col>
              </b-row>
              <b-row class="align-items-center my-1">
                <b-col offset-md="3">
                  <b-button
                    type="submit"
                    size="sm"
                    variant="primary"
                    @click.prevent="
                      updatePassword(
                        row.item.id,
                        password,
                        verificationPassword,
                        row.item.isAdmin,
                      )
                    "
                    >{{ $t("Save") }}</b-button
                  >
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="row.toggleDetails"
                    >{{ $t("Close") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </template>
      </b-table>
      <site-spinner v-if="isLoading" />
    </template>
    <template v-slot:modal-footer>
      <div
        class="w-100 d-flex flex-row justify-content-between align-items-center"
      >
        <b-button
          variant="primary"
          @click="$root.$emit('bv::show::modal', 'user-create')"
          >{{ $t("Add user") }}</b-button
        >
        <b-button variant="secondary" class="float-right" @click="show = false">
          {{ $t("Close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { UserApi } from "@/api";
import SiteSpinner from "@/components/layout/SiteSpinner.vue";
import { User } from "@/model/user/user";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SiteSpinner,
  },
  data() {
    return {
      currentItems: [],
      show: false,
      filter: "",
      perPage: 10,
      currentPage: 2,
      password: "",
      showPassword: false,
      verificationPassword: "",
      showVerification: false,
      fields: [
        { key: "messages", label: "" },
        {
          key: "username",
          label: this.$t("Username"),
        },
        {
          key: "loginCount",
          label: this.$t("Sign in count"),
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "lastLogin",
          label: this.$t("Last sign in"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: "",
          tdClass: "text-right",
          thClass: "action-row",
        },
      ],
    };
  },
  computed: {
    rows() {
      return 30;
    },
    getUsers(): User[] {
      return this.$store.getters["Users/getUsers"];
    },
    isLoading(): boolean {
      return this.$store.getters["Users/isLoading"];
    },
  },
  methods: {
    toggleDetails(row: any) {
      this.password = "";
      this.showPassword = false;
      this.verificationPassword = "";
      this.showVerification = false;

      if (row._showDetails) {
        row._showDetails = false;
      } else {
        this.currentItems.forEach((item: { _showDetails: boolean }) => {
          item._showDetails = false;
        });

        this.$nextTick(() => {
          row._showDetails = true;
        });
      }
    },
    updatePassword(
      userId: number,
      password: string,
      verificationPassword: string,
      isAdmin: boolean,
    ) {
      if (password !== verificationPassword) {
        this.error("Passwords do not match or zero password specified");
        return;
      }

      startLoadingBar();
      UserApi.updatePassordAdmin(userId, password, isAdmin)
        .then(() => this.success("User successfully modified"))
        .catch(() => this.error("@wm_004"))
        .finally(() => endLoadingBar());
    },
    onDelete(username: string, userId: number) {
      const msg = `${this.$t(
        "Would you like to permanently delete user",
      )} ${username}?`;
      if (window.confirm(msg.toString())) {
        this.deleteUser(userId);
      }
    },
    success(messages: string): Promise<void> {
      return this.$store.dispatch("success", messages);
    },
    error(messages: string): Promise<void> {
      return this.$store.dispatch("error", messages);
    },
    deleteUser(userId: number): Promise<void> {
      return this.$store.dispatch("Users/deleteUser", userId);
    },
  },
});
</script>

<style lang="scss">
.full-size {
  width: 100%;
}

.last-sign-in-row {
  width: 160px;
}

.action-row {
  width: 160px;
}
</style>
<style scoped>
.spinner-border {
  height: 80px !important;
  width: 80px !important;
}
</style>
