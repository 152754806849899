import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  endLoadingBar,
  errorNotify,
  startLoadingBar,
} from "../helpers/global.helper";
import { UserApi } from "@/api";
import { User } from "@/model/user/user";

@Module({ namespaced: true })
class UsersStore extends VuexModule {
  public users: User[] = [];
  public loadingState = false;

  get getUsers(): User[] {
    return this.users;
  }

  get isLoading(): boolean {
    return this.loadingState;
  }

  @Mutation
  public setUsers(users: User[]): void {
    this.users = users;
  }

  @Mutation
  public setLoadingState(state: boolean): void {
    this.loadingState = state;
  }

  @Mutation
  public removeUser(id: number) {
    this.users = this.users.filter((user) => user.id !== id);
  }

  @Action
  public async fetchUsers(): Promise<void> {
    startLoadingBar();
    this.context.commit("setLoadingState", true);

    await UserApi.list()
      .then(async (users) => {
        this.context.commit("setUsers", users);
      })
      .finally(() => {
        endLoadingBar();
        this.context.commit("setLoadingState", false);
      });
  }

  @Action
  public async deleteUser(id: number): Promise<void> {
    startLoadingBar();
    await UserApi.deleteAdmin(id)
      .then(() => this.context.commit("removeUser", id))
      .then(() =>
        this.context.dispatch(
          "Notification/success",
          "Delete user successful",
          {
            root: true,
          },
        ),
      )
      .catch(() => errorNotify(`Delete user failed`))
      .finally(() => endLoadingBar());
  }
}
export default UsersStore;
